import { useState } from 'react'
import getConfig from 'next/config'
import { useRouter } from 'next/router'
import Link from 'next/link'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { ToastContainer, toast } from 'react-toastify'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import GoogleLogin from 'react-google-login'
import AppleLogin from 'react-apple-login'
import { useUser, createUserObject } from '../../context/UserContext'
import useApi from '../../hooks/useApi'
import NoHeaderLayout from '../../components/Layout/NoHeader'
import LoginOption from '../../components/LoginOption'
import useSnowplow from '../../hooks/useSnowplow'
import i18n from '../../i18n'

export default function Login({ locale }) {
  const { publicRuntimeConfig } = getConfig()
  const { snowplowTrack, snowplowSetUser } = useSnowplow()

  i18n.changeLanguage(locale)

  const { t } = useTranslation('translation', {
    useSuspense: false,
    bindI18n: false,
  })

  const { createGuestUser, facebookLogin, googleLogin, appleLogin } = useApi()
  const [, setUser] = useUser()
  const router = useRouter()

  const [loading, setLoading] = useState(false)

  const initiateSession = async (user, token, provider) => {
    if (user && token) {
      snowplowSetUser(user.id)
      snowplowTrack({ eventName: 'user_login', property: provider })

      setUser(createUserObject(user, token))
      router.push('/')
    }
  }

  const onAppleLogin = async (appleResponseData) => {
    const {
      error,
      authorization: { id_token },
    } = appleResponseData

    if (error) {
      console.error(error)

      return
    }

    try {
      const { token: guestUserToken } = await createGuestUser()

      const {
        user: remoteUser,
        token: authToken,
        error,
      } = await appleLogin({ id_token, authToken: guestUserToken })

      initiateSession(remoteUser, authToken, 'apple')

      if (error) {
        setLoading(false)
        toast.error(error)
      }
    } catch (error) {
      setLoading(false)
      toast.error(error.message)
    }
  }

  const onGoogleFailure = (error) => {
    console.error(error)
  }

  const onGoogleLogin = async (googleResponseData) => {
    setLoading(true)

    const {
      googleId,
      profileObj: { email, givenName: first_name, familyName: last_name },
      tokenObj: { id_token },
    } = googleResponseData

    try {
      const { token: guestUserToken } = await createGuestUser()
      const {
        user: remoteUser,
        token: authToken,
        error,
      } = await googleLogin({
        first_name,
        last_name,
        email,
        id: googleId,
        id_token,
        authToken: guestUserToken,
      })

      initiateSession(remoteUser, authToken, 'google')

      if (error) {
        setLoading(false)
        toast.error(error)
      }
    } catch (error) {
      setLoading(false)
      toast.error(error.message)
    }
  }

  const onFacebookLogin = async (facebookResponseData) => {
    setLoading(true)

    try {
      const { token: guestUserToken } = await createGuestUser()
      const {
        user: remoteUser,
        token: authToken,
        error,
      } = await facebookLogin({
        ...facebookResponseData,
        authToken: guestUserToken,
      })

      initiateSession(remoteUser, authToken, 'facebook')

      if (error) {
        setLoading(false)
        toast.error(error)
      }
    } catch (error) {
      setLoading(false)
      toast.error(error.message)
    }
  }

  return (
    <>
      <div className="mx-auto flex w-full flex-col justify-start px-8 pt-9 lg:w-2/3">
        <div className="flex justify-start font-link text-2xl xl:justify-center">
          {t('login.title')}
        </div>
        <div className="mt-8 mb-36 flex flex-col space-y-3">
          <LoginOption
            provider="email"
            onClick={() => router.push('login/email')}
            loading={loading}
          />
          <div className="hr-sect font-link text-[#979797] before:bg-ar-gray after:bg-ar-gray">
            OR
          </div>
          <AppleLogin
            clientId={publicRuntimeConfig.appleClientId}
            redirectURI={publicRuntimeConfig.appleRedirectUri}
            scope="name email"
            callback={onAppleLogin}
            usePopup={true}
            render={({ onClick }) => (
              <LoginOption
                provider="apple"
                onClick={onClick}
                loading={loading}
              />
            )}
          />
          <GoogleLogin
            clientId={publicRuntimeConfig.googleClientId}
            onSuccess={onGoogleLogin}
            onFailure={onGoogleFailure}
            responseType={'id_token'}
            cookiePolicy={'single_host_origin'}
            render={({ onClick, disabled }) => (
              <LoginOption
                provider="google"
                onClick={onClick}
                loading={disabled || loading}
              />
            )}
          />
          <FacebookLogin
            appId={publicRuntimeConfig.facebookAppId}
            autoLogin={false}
            fields="id,first_name,last_name,email,gender"
            callback={onFacebookLogin}
            onFailure={() => {}}
            render={({ onClick, isDisabled, isProcessing }) => (
              <LoginOption
                provider="facebook"
                onClick={onClick}
                loading={isDisabled || isProcessing || loading}
              />
            )}
          />

          <Link href="/forgot-password">
            <a className="text-center font-link text-sm text-ar-dark-green">
              {t('login.forgot_password')}
            </a>
          </Link>
        </div>
      </div>

      <span className="fixed bottom-2 left-0 right-0 mt-3 mb-4 block w-full text-center font-body text-sm text-ar-dark-gray">
        {t('login.no_account')}&nbsp;
        <a
          href={publicRuntimeConfig.webFunnelUrl}
          className="font-link text-ar-dark-green">
          {t('login.signup')}
        </a>
      </span>

      <ToastContainer position="bottom-center" hideProgressBar />
    </>
  )
}

export async function getServerSideProps({ req }) {
  const locale = req.headers['accept-language'].split(',')[0] || 'en'

  return {
    props: {
      locale: locale,
    },
  }
}

Login.propTypes = {
  locale: PropTypes.string.isRequired,
}

Login.getLayout = function getLayout(page) {
  return <NoHeaderLayout>{page}</NoHeaderLayout>
}
