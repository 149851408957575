import PropTypes from 'prop-types'
import Link from 'next/link'
import BaseLayout from '../Base'
import Logo from '../../../public/icons/logo.svg'

export default function NoHeaderLayout({ children }) {
  return (
    <BaseLayout>
      <header className="ml-8 flex flex-wrap pt-8 lg:ml-32">
        <div className="w-screen">
          <Link href="/" passHref>
            <Logo className="cursor-pointer" />
          </Link>
        </div>
      </header>
      <main>{children}</main>
    </BaseLayout>
  )
}

NoHeaderLayout.propTypes = {
  children: PropTypes.node.isRequired,
}
