import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import EmailIcon from '../../public/icons/email.svg'
import AppleIcon from '../../public/icons/apple.svg'
import GoogleIcon from '../../public/icons/google.svg'
import FacebookIcon from '../../public/icons/facebook.svg'

export default function LoginOption({ provider, onClick, loading }) {
  const { t } = useTranslation('translation', {
    useSuspense: false,
    bindI18n: false,
  })

  const LOGIN_OPTIONS = {
    email: {
      icon: <EmailIcon />,
      text: t('login.continue_with') + ' Email',
    },
    apple: {
      icon: <AppleIcon />,
      text: t('login.continue_with') + ' Apple',
    },
    google: {
      icon: <GoogleIcon />,
      text: t('login.continue_with') + ' Google',
    },
    facebook: {
      icon: <FacebookIcon />,
      text: t('login.continue_with') + ' Facebook',
    },
  }

  return (
    <button
      onClick={onClick}
      type="button"
      disabled={loading}
      className={`text-md m-auto block flex w-full justify-center space-x-8 rounded-lg border-2 border-ar-gray p-4
      text-center font-link text-ar-dark-gray hover:bg-ar-gray focus:outline-none lg:p-4
      ${loading ? 'cursor-wait bg-ar-gray' : 'bg-transparent'}`}>
      {LOGIN_OPTIONS[provider].icon}
      <span className="text-left">{LOGIN_OPTIONS[provider].text}</span>
    </button>
  )
}

LoginOption.propTypes = {
  loading: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  provider: PropTypes.string.isRequired,
}
